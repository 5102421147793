


































import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import { ProductListItem } from '@/shared/models/cakes'
import CakeCard from '@/components/cakes/card/Card'
import ArrowIcon from '@/components/icons/ArrowIcon'
import WidgetTitle from '@/components/common/WidgetTitle.vue'
import CakeCardSkeleton from '@/components/cakes/card/CardSkeleton.vue'

@Component({
  components: {
    VueSlickCarousel,
    CakeCard,
    ArrowIcon,
    WidgetTitle,
    CakeCardSkeleton
  }
})
export default class ProductCarousel extends Vue {
  @Prop({ default: 3 }) slidesToShow!: number
  @Prop({ default: '' }) title!: string
  @Prop({
    required: true,
    default: () => []
  }) list!: Array<ProductListItem>

  sliderConfig = {
    slidesToShow: this.slidesToShow || 4,
    slidesToScroll: 1,
    draggable: false,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          swipe: false
        }
      }
    ]
  }
}
