








import { Component, Vue } from 'vue-property-decorator'
import MainSlider from '@/components/MainSlider'
import CakeCategories from '@/components/widgets/CakeCategories'
import CakeTopSales from '@/components/widgets/CakeTopSales'

@Component({
  components: {
    MainSlider,
    CakeCategories,
    CakeTopSales
  }
})
export default class FrontPage extends Vue {}
