






import Vue from 'vue'
import Component from 'vue-class-component'
import API from '@/api/products'
import { ProductListItem, ProductListParams } from '@/shared/models/cakes'
import ProductCarousel from '@/components/ProductCarousel'

@Component({
  components: {
    ProductCarousel
  }
})
export default class CakeTopSales extends Vue {
  list: Array<ProductListItem> = []
  params: ProductListParams = {
    tag: 20 // top sales term id
  }

  mounted(): void {
    API.getCakes(this.params).then(response => {
      this.list = response?.data || []
    })
  }
}
