




















import Vue from 'vue'
import Component from 'vue-class-component'
import { CakeCategoryItem } from '@/shared/models/cakes'

@Component
export default class CakeCategories extends Vue {
  get cakeCategories(): Array<CakeCategoryItem> {
    return this.$store.getters.categories || []
  }
}
