

























































import Vue from 'vue'
import Component from 'vue-class-component'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import { MainSlide } from '@/shared/models/main-slider'
import BezeButton from '@/components/common/BezeButton.vue'
import ArrowIcon from '@/components/icons/ArrowIcon'
import Loader from '@/components/common/Loader.vue'

@Component({
  components: {
    VueSlickCarousel,
    BezeButton,
    ArrowIcon,
    Loader
  }
})
export default class MainSlider extends Vue {
  isSlideShowing = false
  currentSlideIdx = 0
  sliderConfig = {
    autoplay: true,
    fade: true,
    arrows: false,
    infinite: true,
    pauseOnHover: false,
    lazyLoad: 'progressive',
    autoplaySpeed: 8000,
    speed: 1200,
    initialSlide: this.currentSlideIdx
  }

  get slides(): Array<MainSlide> {
    return this.$store.getters.slides
  }

  mounted(): void {
    !this.slides.length && this.$store.dispatch('getSlides')
  }

  changeCurSlideIdx(prevIdx: number, nextIdx: number): void {
    this.currentSlideIdx = nextIdx
    this.isSlideShowing = false
  }
}
